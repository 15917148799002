/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useEffect, useState } from 'react';
import './App.css';
import { io, Socket } from 'socket.io-client';

let socket: Socket;

function App() {
  const [streamImage, setStreamImage] = useState('');
  const [rooms, setRooms] = useState<string[]>([]);
  const [currentRoom, setCurrentRoom] = useState<string>('');
  const [config, setConfig] = useState<{
    led: number | null;
    framesize: number | null;
    rotate: number | null;
  }>({
    led: null,
    framesize: null,
    rotate: null,
  });

  useEffect(() => {
    socket = io('https://iot-cam.sangsang.farm/cam', {
      transports: ['websocket'],
    });

    const interval = setInterval(() => {
      socket?.emit('getRooms');
    }, 1000 * 30);
    socket.on('connect', () => {
      socket.on('stream', (data) => {
        if (data.image) {
          setStreamImage(`${data.image}`);
        }
      });

      socket.on('getRooms', (data: string[]) => {
        setRooms(data);
      });

      socket.on('getRoomClient', (data: string[]) => {
        // eslint-disable-next-line no-console
        console.log(data);
      });

      socket.emit('getRooms');
    });

    return (): void => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="App">
      <div className="flex flex-row content-center justify-center space-x-4 p-3">
        {rooms.map((room, index) => (
          <button
            className="rounded-full bg-blue-500 px-5 py-1 text-gray-100"
            key={index}
            onClick={(): void => {
              setStreamImage('');
              if (currentRoom !== room) {
                setConfig({ led: null, rotate: null, framesize: null });
                socket?.emit('leaveRoom', currentRoom);
                socket?.emit('joinRoom', room);
                setCurrentRoom(room);
              }
            }}
          >
            {room.split('___')[1]}
          </button>
        ))}
        <button
          className="rounded-full bg-red-500 px-5 py-1 text-gray-100"
          onClick={(): void => {
            setConfig({ led: null, rotate: null, framesize: null });
            setStreamImage('');
            socket?.emit('leaveRoom', currentRoom);
            setCurrentRoom('');
          }}
        >
          스트리밍 종료
        </button>
      </div>

      {streamImage !== '' ? (
        <div>
          <div className="flex flex-row justify-center space-x-4 p-3">
            <h2 className="my-auto">LED</h2>
            <button
              className="rounded-full bg-blue-500 px-5 py-1 text-gray-100"
              onClick={(): void => {
                if (currentRoom !== '' && config.led !== 255) {
                  setConfig({ ...config, led: 255 });
                  socket?.emit('config', { roomName: currentRoom, led: 255 });
                }
              }}
            >
              ON
            </button>
            <button
              className="rounded-full bg-blue-500 px-5 py-1 text-gray-100"
              onClick={(): void => {
                if (currentRoom !== '' && config.led !== 0) {
                  setConfig({ ...config, led: 0 });
                  socket?.emit('config', { roomName: currentRoom, led: 0 });
                }
              }}
            >
              OFF
            </button>
          </div>
          <div className="flex flex-row justify-center space-x-4 p-3">
            <h2 className="my-auto">화질</h2>
            <button
              className="rounded-full bg-blue-500 px-5 py-1 text-gray-100"
              onClick={(): void => {
                if (currentRoom !== '' && config.framesize !== 5) {
                  setConfig({ ...config, framesize: 5 });
                  socket?.emit('config', {
                    roomName: currentRoom,
                    stream: { framesize: 5 },
                  });
                }
              }}
            >
              360P
            </button>
            <button
              className="rounded-full bg-blue-500 px-5 py-1 text-gray-100"
              onClick={(): void => {
                if (currentRoom !== '' && config.framesize !== 8) {
                  setConfig({ ...config, framesize: 8 });
                  socket?.emit('config', {
                    roomName: currentRoom,
                    stream: { framesize: 8 },
                  });
                }
              }}
            >
              480P
            </button>
            <button
              className="rounded-full bg-blue-500 px-5 py-1 text-gray-100"
              onClick={(): void => {
                if (currentRoom !== '' && config.framesize !== 11) {
                  setConfig({ ...config, framesize: 11 });
                  socket?.emit('config', {
                    roomName: currentRoom,
                    stream: { framesize: 11 },
                  });
                }
              }}
            >
              720P
            </button>
            <button
              className="rounded-full bg-blue-500 px-5 py-1 text-gray-100"
              onClick={(): void => {
                if (currentRoom !== '' && config.framesize !== 13) {
                  setConfig({ ...config, framesize: 13 });
                  socket?.emit('config', {
                    roomName: currentRoom,
                    stream: { framesize: 13 },
                  });
                }
              }}
            >
              최대
            </button>
          </div>
          <div className="flex flex-row justify-center space-x-4 p-3">
            <h2 className="my-auto">이미지 회전</h2>
            <button
              className="rounded-full bg-blue-500 px-5 py-1 text-gray-100"
              onClick={(): void => {
                if (currentRoom !== '' && config.rotate !== 0) {
                  setConfig({ ...config, rotate: 0 });
                  socket?.emit('config', {
                    roomName: currentRoom,
                    rotate: 0,
                  });
                }
              }}
            >
              0
            </button>
            <button
              className="rounded-full bg-blue-500 px-5 py-1 text-gray-100"
              onClick={(): void => {
                if (currentRoom !== '' && config.rotate !== 90) {
                  setConfig({ ...config, rotate: 90 });
                  socket?.emit('config', {
                    roomName: currentRoom,
                    rotate: 90,
                  });
                }
              }}
            >
              90
            </button>
            <button
              className="rounded-full bg-blue-500 px-5 py-1 text-gray-100"
              onClick={(): void => {
                if (currentRoom !== '' && config.rotate !== 180) {
                  setConfig({ ...config, rotate: 180 });
                  socket?.emit('config', {
                    roomName: currentRoom,
                    rotate: 180,
                  });
                }
              }}
            >
              180
            </button>
            <button
              className="rounded-full bg-blue-500 px-5 py-1 text-gray-100"
              onClick={(): void => {
                if (currentRoom !== '' && config.rotate !== 270) {
                  setConfig({ ...config, rotate: 270 });
                  socket?.emit('config', {
                    roomName: currentRoom,
                    rotate: 270,
                  });
                }
              }}
            >
              270
            </button>
          </div>
          <div className="flex justify-center">
            <img src={streamImage} width={'100%'} alt="stream" />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default App;
